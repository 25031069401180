@use "sass:math";

.footer {
  &-section {
    padding: $space 0;
    .social-links {
      display: flex;
      align-items: center;
      margin: $space 0;

      li{
        margin: 0 ($space - 0.5);

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .footer-address-wrap{
      padding-left: $space+0.5;

      p{
        color: #fff;
      }

      .timings{
        margin-bottom: 0;

        span{
          font-weight: bold;
        }
      }
    }

    .footer-other-wrap{
      li{
        margin-bottom: $space - 0.75;

        a{
          font-size: 16px;
        }
      }
    }
  }

  &-contact-wrap {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #323232;
    
    .logo-wrap {
      width: 100%;
      margin-bottom: 32px;
      padding-right: 32px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .prm-contact {
      font-size: 26px;
      font-weight: 300;
      color: #2761b7;
    }

    .prm-contact,
    .sec-contact {
      margin-bottom: 6px;
    }
  }

  &-upper-wrapper {
    padding: ($space + 0.25) 10%;
    color: $white;
  }

  &-lower-wrapper {
    background-color: $white;
    padding: ($space + 0.25) ($space + 0.5);

    p {
      margin: 0;
      font-size: 15px;
      font-weight: 300;
      color: #000000;
    }
  }
}


.social-ico {
  flex-shrink: 0;
  height: 26px;
  width: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;

  &.facebook {
    -webkit-mask: url("../social-ico/facebook.svg") no-repeat center;
    mask: url("../social-ico/facebook.svg") no-repeat center;
    
    &:hover{
      background-color: #1877F2;
    }
  }

  &.twitter {
    -webkit-mask: url("../social-ico/twitter.svg") no-repeat center;
    mask: url("../social-ico/twitter.svg") no-repeat center;
    
    &:hover{
      background-color: #1DA1F2;
    }
  }

  &.youtube {
    -webkit-mask: url("../social-ico/youtube.svg") no-repeat center;
    mask: url("../social-ico/youtube.svg") no-repeat center;
    
    &:hover{
      background-color: #FF0000;
    }
  }

  &.linkedin {
    -webkit-mask: url("../social-ico/linkedin.svg") no-repeat center;
    mask: url("../social-ico/linkedin.svg") no-repeat center;
    
    &:hover{
      background-color: #0A66C2;
    }
  }
}