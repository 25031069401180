.tech-icon {
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $white;

  &.wood-floor{
    -webkit-mask: url("../img/icons/wood-floor.svg") no-repeat center;
    mask: url("../img/icons/wood-floor.svg") no-repeat center;
  }
}