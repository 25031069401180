@import "../../../node_modules/@splidejs/splide/dist/css/splide.min.css";
@import "../vendors/materialize-src/sass/materialize.scss";
@import "../../../node_modules/accordion-js/dist/accordion.min.css";

// @include textfield.core-styles;

@import 
  "../bourbon/bourbon", 
  "./parts/variables.scss", 
  "./parts/vendors.scss",
  "./parts/mixins.scss", 
  "./parts/components.scss";

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.input-field {
  border-color: #FFFFFF;
  
  input {
    color: #FFFFFF;
    height: 2rem !important;
  }

  & > label {
    left: 8px;
    color: #fff;
    transform: translateY(6px);
    font-size: 14px;
    font-family: 'Poppins';
  }
}